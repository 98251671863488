import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Layout, SEO } from '@global'
import { Testimonial, SocialProof } from '@layouts'
import { useWindowWidth, useInc } from '@hooks'

const TestimonialsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityTestimonials(sort: { fields: _createdAt, order: DESC }) {
        edges {
          node {
            _id
            testimonial
            name
            city
          }
        }
      }
    }
  `)

  const width = useWindowWidth()
  let cardCount = width >= 640 && width < 1024 ? 2 : 3
  const [cardsToShow, { inc, reset }] = useInc({
    minValue: 6,
    initial: 6,
    step: cardCount * 2,
  })
  return (
    <div className="relative">
      <SEO title="Testimonials" />
      <Layout>
        <section className="py-10 bg-gray-100 bg-repeat-y bg-cover testimonials-bg sm:py-12">
          <div className="container">
            <div className="text-center">
              <h2 className="font-serif text-5xl font-bold text-blue-400 capitalize lg:text-6xl">
                testimonials
              </h2>
              <p className="max-w-sm mx-auto mt-1 text-base text-lg leading-snug text-gray-400 lg:max-w-none">
                We value your feedback. Read what our customers have to say.
              </p>
            </div>
            <div className="grid max-w-5xl gap-8 mx-auto mt-12 sm:mt-16 lg:mt-24 sm:gap-5 sm:grid-cols-2 lg:grid-cols-3">
              {data.allSanityTestimonials.edges
                .slice(0, cardsToShow)
                .map(({ node }) => (
                  <Testimonial
                    key={node._id}
                    name={node.name}
                    location={node.city}
                    content={node.testimonial}
                  />
                ))}
            </div>
            <div className="flex items-center justify-center pt-10 pb-5 lg:pt-20">
              {cardsToShow < data.allSanityTestimonials.edges.length ? (
                <button onClick={inc} className="text-lg btn sm:text-xl">
                  Show More
                </button>
              ) : (
                <button
                  onClick={() => {
                    reset()
                    window.scrollTo(0, 0)
                  }}
                  className="text-lg btn sm:text-xl"
                >
                  Back to top
                </button>
              )}
            </div>
          </div>
        </section>
        <SocialProof />
      </Layout>
    </div>
  )
}
export default TestimonialsPage
